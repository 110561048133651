(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('IndividualReleaseGroupDetailController', IndividualReleaseGroupDetailController);

    IndividualReleaseGroupDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'IndividualReleaseGroup', 'Company'];

    function IndividualReleaseGroupDetailController($scope, $rootScope, $stateParams, previousState, entity, IndividualReleaseGroup, Company) {
        var vm = this;

        vm.individualReleaseGroup = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('aditumApp:individualReleaseGroupUpdate', function(event, result) {
            vm.individualReleaseGroup = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
