(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('IndividualReleaseGroupController', IndividualReleaseGroupController);

    IndividualReleaseGroupController.$inject = ['IndividualReleaseGroup','globalCompany'];

    function IndividualReleaseGroupController(IndividualReleaseGroup,globalCompany) {
        var vm = this;
        vm.groups = [];  // Lista de todos los grupos
        vm.group = {};   // Detalles de un solo grupo

        // Obtener todos los grupos de liberación
        vm.getAllGroups = function() {
            IndividualReleaseGroup.byCompany({companyId:globalCompany.getId()},
                function (response) {
                    vm.groups = response;
                },
                function (error) {
                    console.error('Error al cargar los grupos:', error);
                }
            );
        };

        // Crear un nuevo grupo de liberación
        vm.createGroup = function() {
            var newGroup = {
                name: 'Nuevo Grupo',
                clients: 'ClienteA, ClienteB',
                deleted: false,
                companyId: 123
            };

            IndividualReleaseGroup.save(newGroup, function(response) {  // Usamos save para crear el grupo
                console.log('Grupo creado:', response);
                vm.groups.push(response); // Agregar el grupo recién creado al listado
            }, function(error) {
                console.error("Error al crear el grupo: ", error);
            });
        };

        // Obtener un grupo por su ID
        vm.getGroupById = function(id) {
            IndividualReleaseGroup.get({ id: id }, function(response) {
                console.log('Grupo encontrado:', response);
                vm.group = response; // Asignar el grupo encontrado
            }, function(error) {
                console.error("Error al obtener el grupo: ", error);
            });
        };

        // Actualizar un grupo de liberación
        vm.updateGroup = function() {
            var updatedGroup = angular.copy(vm.group); // Clonar el grupo antes de enviarlo
            updatedGroup.name = 'Grupo Actualizado';

            IndividualReleaseGroup.update(updatedGroup, function(response) {
                console.log('Grupo actualizado:', response);
                vm.group = response; // Actualizar el grupo en la vista
            }, function(error) {
                console.error("Error al actualizar el grupo: ", error);
            });
        };

        // Eliminar un grupo de liberación por ID
        vm.deleteGroup = function(id) {
            IndividualReleaseGroup.delete({ id: id }, function(response) {
                console.log('Grupo eliminado');
                vm.groups = vm.groups.filter(function (group) {
                    return group.id !== id;
                }); // Eliminar del listado
            }, function(error) {
                console.error("Error al eliminar el grupo: ", error);
            });
        };
    }
})();
