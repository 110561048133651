(function() {
    'use strict';
    angular
        .module('aditumApp')
        .factory('IndividualReleaseGroup', IndividualReleaseGroup);

    IndividualReleaseGroup.$inject = ['$resource'];

    function IndividualReleaseGroup($resource) {
        var resourceUrl = 'api/individual-release-groups/:id'; // URL base

        return $resource(resourceUrl, {}, {
            // GET: Manejar respuesta como array
            'query': {
                method: 'GET',
                isArray: true
            },
            'byCompany': {
                method: "GET",
                url:"api/individual-release-groups-by-company/:companyId",
                isArray: true
            },
            // GET por id: Manejar respuesta como objeto
            'get': {
                method: 'GET',
                isArray: true // Cambiar aquí si `get` manejará un array
            },
            // POST: Crea un nuevo registro
            'create': {
                method: 'POST'
            },
            // PUT: Actualiza un registro existente
            'update': {
                method: 'PUT'
            },
            // DELETE: Elimina un registro por su id
            'delete': {
                method: 'DELETE'
            }
        });
    }
})();
