(function() {
    'use strict';

    angular
        .module('aditumApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('individual-release-group', {
                parent: 'entity',
                authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT'],  // Roles actualizados
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT'],
                    pageTitle: 'aditumApp.individualReleaseGroup.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/individual-release-group/individual-release-groups.html',
                        controller: 'IndividualReleaseGroupController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('individualReleaseGroup');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('individual-release-group-detail', {
                parent: 'individual-release-group',
                url: '/individual-release-group/{id}',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT'],
                    pageTitle: 'aditumApp.individualReleaseGroup.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/individual-release-group/individual-release-group-detail.html',
                        controller: 'IndividualReleaseGroupDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('individualReleaseGroup');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'IndividualReleaseGroup', function($stateParams, IndividualReleaseGroup) {
                        return IndividualReleaseGroup.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'individual-release-group',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('individual-release-group-detail.edit', {
                parent: 'individual-release-group-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/individual-release-group/individual-release-group-dialog.html',
                        controller: 'IndividualReleaseGroupDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['IndividualReleaseGroup', function(IndividualReleaseGroup) {
                                return IndividualReleaseGroup.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^', {}, { reload: false });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('individual-release-group.new', {
                parent: 'individual-release-group',
                url: '/new',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/individual-release-group/individual-release-group-dialog.html',
                        controller: 'IndividualReleaseGroupDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    clients: null,
                                    deleted: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('individual-release-group', null, { reload: 'individual-release-group' });
                    }, function() {
                        $state.go('individual-release-group');
                    });
                }]
            })
            .state('individual-release-group.edit', {
                parent: 'individual-release-group',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_OWNER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/individual-release-group/individual-release-group-dialog.html',
                        controller: 'IndividualReleaseGroupDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['IndividualReleaseGroup', function(IndividualReleaseGroup) {
                                return IndividualReleaseGroup.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('individual-release-group', null, { reload: 'individual-release-group' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('individual-release-group.delete', {
                parent: 'individual-release-group',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/individual-release-group/individual-release-group-delete-dialog.html',
                        controller: 'IndividualReleaseGroupDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['IndividualReleaseGroup', function(IndividualReleaseGroup) {
                                return IndividualReleaseGroup.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('individual-release-group', null, { reload: 'individual-release-group' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }

})();
